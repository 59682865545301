:root {
  --bg-card: #1E2330;
  --bg-foter: #07090F;
  --bg-main: #FFFFFF;
  --bg-modal: linear-gradient(180deg, #1E2330 0%, #000614 100%);
  --bg-navbar: #0D0F14;
  --bg-popup: linear-gradient(180deg, #EC0000 0%, #9C0000 100%);
  --bg-text-animation: #1E2330;
  --bt-confirm: linear-gradient(90deg, #CD9816 0%, #FFCF68 21.09%, #FFE8AC 49.22%, #FFCF68 75.78%, #CD9816 100%);
  --bt-danger: #FF0000;
  --bt-defult: #FFFFFF;
  --bt-info: #434958;
  --bt-login: #FFDE8B;
  --bt-register: #2B00D6;
  --bt-secondary: #C9C9C9;
  --bt-sucess: #00ED09;
  --bt-undefult: #000000;
  --bt-warning: #FFD600;
}

.App {
  min-height: 100vh;
  text-align: center;
}

.progressiveImage {
  width: 100%;
}

.relative {
  position: relative;
}

.wrapper {
  padding-top: 40px;
}

.wrapperActive {
  padding-top: 40px;
}

.homePosition {
  padding-top: 78px;
}

.homePositionActive {
  padding-top: 124px;
}

/* .wrapper .content {
  padding: 12px;
} */

.bgContainer {
  /* background: var(--bg); */
  padding: 0px !important;
  min-height: 100vh;
}

.bgContainerMargin {
  margin-bottom: 0px !important;
}

.bgWhite {
  background: white;
}

.bgPadding {
  padding: 12px 0px;
  border-radius: 15px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.boxGame {
  /* padding: 30px 0px; */
}

.cardMargin {
  margin-top: 30px;
}

.GamePlayApp {
  position: fixed;
  top: 0px;
  width: 100%;
  left: 0px;
}

.GamePlayPopupApp {
  position: fixed;
  top: 0px;
  width: 100%;
  left: 0px;
  background: rgb(7, 9, 15);
  z-index: 2000;
}

.GamePlayPopupHeight {
  min-height: 100vh;
}

.center {
  text-align: center;
}

/* .slick-track {
  display: flex !important;
} */

/* .slick-slide {
  width: 100%;
} */

.flexContent {
  display: flex;
}

.flexContentCenter {
  align-items: center;
}

.react-datepicker-popper {
  z-index: 1205 !important;
}

.HomeApp .slick-dots {
  top: -50px !important;
  right: 0px;
  bottom: 0px !important;
  width: unset;
  height: 40px;
}

#Branner {
  margin-bottom: -5px;
}

#Branner .slick-dots {
  bottom: 20px !important;
}

#Branner .slick-prev {
  left: 17px !important;
}

#Branner .slick-next {
  right: 30px !important;
}

/* .HomeApp .slick-slide {
  width: 600px !important;
  box-sizing: border-box 
} */

.slick-dots li button:before {
  color: var(--bt-defult) !important;
  font-size: 24px !important;
  content: "▬" !important;
}

.HomeApp .slick-dots li {
  width: 16px;
  height: 16px;
}

.HomeApp .slick-dots li button:before {
  color: var(--bg-main) !important;
  font-size: 24px !important;
  content: "●" !important;
}

.HomeApp .MuiTab-textColorInherit {
  font-family: 'Sarabun', sans-serif;
  position: relative;
  background: var(--bg-card);
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  padding: 36px 0px;
  margin-bottom: 10px;
  /* box-shadow: 0px 0px 5px var(--bt-undefult); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  opacity: 1;
  color: var(--bg-main);
  width: 97%;
  margin-left: 3px;
}

.MuiDrawer-paperAnchorRight {
  background-color: var(--bg-card) !important;
}

.HomeApp .MuiTab-textColorInherit:hover {
  top: 2px;
  background: var(--bg-popup);
  color: var(--bt-defult);
}

.HomeApp .MuiTab-textColorInherit.Mui-selected {
  font-family: 'Sarabun', sans-serif;
  background: var(--bg-popup);
  color: var(--bt-defult);
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  padding: 36px 0px;
  margin-bottom: 10px;
  /* box-shadow: 0px 0px 5px var(--bt-undefult); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 97%;
  margin-left: 3px;
}

.HomeApp .MuiTabScrollButton-root.Mui-disabled {
  display: none;
}

.HomeApp .MuiTabs-indicator {
  background-color: unset;
}

.HomeApp .MuiSvgIcon-fontSizeSmall {
  font-size: 30px;
}

.HomeApp .MuiTabScrollButton-vertical {
  background: var(--bg-card);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px var(--bt-undefult); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 97%;
  margin-left: 3px;
}

.slick-prev,
.slick-next {
  z-index: 1000;
  display: unset !important;
}

.slick-prev::before {
  font-size: 35px !important;
  color: #fff !important;
  opacity: 0.5 !important;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 80%));
}

.slick-next::before {
  font-size: 35px !important;
  color: #fff !important;
  opacity: 0.5 !important;
  filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 40%));
}

.slick-prev {
  left: -18px !important;
}

.slick-next {
  right: -2px !important;
}

/* .PromotionApp .slick-slide {
  width: 580px !important;
}

.PromotionApp .slick-dots {
  bottom: -60px !important;
}

.PromotionApp .slick-prev {
  left: 30px !important;
}

.PromotionApp .slick-next {
  right: 30px !important;
} */

#DepositApp .MuiInput-underline:before {
  border-bottom: none !important;
}

#DepositApp ::-webkit-scrollbar,
#HomeApp ::-webkit-scrollbar {
  width: 18px;
  height: 18px;
  background: unset;
}

#DepositApp ::-webkit-scrollbar-thumb,
#HomeApp ::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

#DepositInput .MuiInputBase-input {
  text-align: center;
  padding: 0px 0px 6px 0px !important;
  font-size: 20px;
}

#DepositDate .MuiInput-underline:before {
  border-bottom: 0px !important;
}

#DepositDate .MuiInputBase-input {
  text-align: right !important;
}

.displayFlex {
  display: flex;
  width: 100%;
}

.alignItemsCenter {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.flexSizeLeft {
  flex: 0 0 20%;
}

.flexSizeRight {
  flex: 0 0 80%;
}

.labelDanger {
  background: var(--bt-danger);
  color: var(--bt-defult);
  font-size: 13px;
  border-radius: 40px;
  padding: 2px 12px;
}

/* Animation */
.move-animate {
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
  -webkit-animation-name: move-animate;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  -webkit-animation-iteration-count: infinite;
}

@keyframes move-animate {

  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
}

.coin {
  animation: coinAnimate 0.5s steps(14) infinite;
}

@keyframes coinAnimate {
  from {
    background-position: 0 0%;
  }

  to {
    background-position: 100% 0;
  }
}

/* End Animation */

/* Loadder */
.loadderFixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  /* background: black; */
  color: var(--bt-defult);
}

.loadderCenter,
.preLoadderCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.loadPage {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.816);
  transform: translate(0px, 0px) !important;
  color: white;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: var(--bt-defult);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loadContent {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  text-align: center;
  padding-top: 20px;
}

.loadContentText {
  font-size: 18px;
  margin-top: 20px;
  color: var(--bt-defult)
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--bt-defult);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--bt-defult) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .lds-shape {
  max-width: 160px;
} */

.lds-shape ul {
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  -webkit-animation: rot 16s linear infinite;
  animation: rot 16s linear infinite;
  width: 100px;
  position: relative;
}

@-webkit-keyframes rot {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rot {
  100% {
    transform: rotate(360deg);
  }
}

.lds-shape li {
  width: 24px;
  height: 24px;
  background: var(--bt-login);
  border-radius: 4px;
  box-shadow: 0 0 1px var(--bt-defult), 0 0 5px var(--bt-login), 0 0 10px var(--bt-login), 0 0 15px var(--bt-login),
    0 0 25px var(--bt-login), 0 0 55px var(--bt-login);
  -webkit-animation: scale 0.8s linear alternate infinite;
  animation: scale 0.8s linear alternate infinite;
}

@-webkit-keyframes scale {
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes scale {
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

.lds-shape li:nth-child(1) {
  z-index: 24;
}

.lds-shape li:nth-child(2) {
  z-index: 23;
}

.lds-shape li:nth-child(3) {
  z-index: 22;
}

.lds-shape li:nth-child(4) {
  z-index: 21;
}

.lds-shape li:nth-child(5) {
  z-index: 20;
}

.lds-shape li:nth-child(6) {
  z-index: 19;
}

.lds-shape li:nth-child(7) {
  z-index: 18;
}

.lds-shape li:nth-child(8) {
  z-index: 17;
}

.lds-shape li:nth-child(9) {
  z-index: 16;
}

.lds-shape li:nth-child(10) {
  z-index: 15;
}

.lds-shape li:nth-child(11) {
  z-index: 14;
}

.lds-shape li:nth-child(12) {
  z-index: 13;
}

.lds-shape li:nth-child(13) {
  z-index: 12;
}

.lds-shape li:nth-child(14) {
  z-index: 11;
}

.lds-shape li:nth-child(15) {
  z-index: 10;
}

.lds-shape li:nth-child(16) {
  z-index: 9;
}

.lds-shape li:nth-child(17) {
  z-index: 8;
}

.lds-shape li:nth-child(18) {
  z-index: 7;
}

.lds-shape li:nth-child(19) {
  z-index: 6;
}

.lds-shape li:nth-child(20) {
  z-index: 5;
}

.lds-shape li:nth-child(21) {
  z-index: 4;
}

.lds-shape li:nth-child(22) {
  z-index: 3;
}

.lds-shape li:nth-child(23) {
  z-index: 2;
}

.lds-shape li:nth-child(24) {
  z-index: 1;
}

.lds-shape li:nth-child(25) {
  z-index: 0;
}

.lds-shape li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.lds-shape li:nth-child(7) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.lds-shape li:nth-child(13) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.lds-shape li:nth-child(19) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.lds-shape li:nth-child(24) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.lds-shape li:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.lds-shape li:nth-child(8) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.lds-shape li:nth-child(14) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.lds-shape li:nth-child(20) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.lds-shape li:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.lds-shape li:nth-child(9) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.lds-shape li:nth-child(15) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.lds-shape li:nth-child(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.lds-shape li:nth-child(10) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.lds-shape li:nth-child(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.lds-shape li:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.lds-shape li:nth-child(6) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.lds-shape li:nth-child(11) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.lds-shape li:nth-child(16) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.lds-shape li:nth-child(21) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.lds-shape li:nth-child(7) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.lds-shape li:nth-child(12) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.lds-shape li:nth-child(17) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.lds-shape li:nth-child(22) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.lds-shape li:nth-child(13) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.lds-shape li:nth-child(18) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.lds-shape li:nth-child(23) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.lds-shape li:nth-child(19) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.lds-shape li:nth-child(24) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.lds-shape li:nth-child(25) {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.textLoadding {
  margin-top: 36px;
  font-size: 20px;
  color: #d9d9d9;
}

/* End Loadder */

/* Dropdown Menu */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background: #360057;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: #fefb95;
  width: 22px;
  height: 22px;
}

.dropdown {
  position: absolute;
  top: 60px;
  width: 180px;
  transform: translateX(-70%);
  background: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 0.4rem 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
}

/* .menu {
  width: 100%;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  cursor: pointer;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
} */

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit {}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

/* Text Error */
.validateInfo {
  padding-top: 6px;
  font-size: 14px;
  color: #b3caff;
  padding-left: 8px;
}

.validateWarning {
  padding-top: 6px;
  font-size: 14px;
  color: #b2caff;
  padding-left: 8px;
}

.validateError {
  padding-top: 6px;
  font-size: 14px;
  color: #ff6877;
  padding-left: 8px;
}

/* End Text Error */

/* SVG Animate */
.svg-box {
  display: inline-block;
  position: relative;
  width: 150px;
  transform: scale(1.3, 1.3);
  margin-top: 20px;
  margin-bottom: 10px;
}

.img-box {
  margin-top: 25px;
  margin-bottom: 0px;
}

.img-wheel-reward {
  width: 120px;
  height: auto;
}

.green-stroke {
  stroke: #7CB342;
}

.red-stroke {
  stroke: #FF6245;
}

.yellow-stroke {
  stroke: #FFC107;
}

.circular {
  /* stroke-width: 300px; */
  /* stroke-: 300px; */
}

.circular circle.path {
  stroke-dasharray: 330;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  opacity: 0.4;
  animation: 0.7s draw-circle ease-out;
}

/*------- Checkmark ---------*/
.checkmark {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 56px;
  left: 49px;
  width: 62px;
  height: 50px;
}

.checkmark path {
  animation: 1s draw-check ease-out;
}

@keyframes draw-circle {
  0% {
    stroke-dasharray: 0, 330;
    stroke-dashoffset: 0;
    opacity: 1;
  }

  80% {
    stroke-dasharray: 330, 330;
    stroke-dashoffset: 0;
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes draw-check {
  0% {
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
    opacity: 0;
  }

  50% {
    stroke-dasharray: 49, 80;
    stroke-dashoffset: 48;
    opacity: 1;
  }

  100% {
    stroke-dasharray: 130, 80;
    stroke-dashoffset: 48;
  }
}

/*---------- Cross ----------*/

.cross {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 54px;
  left: 54px;
  width: 40px;
  height: 40px;
}

.cross .first-line {
  animation: 0.7s draw-first-line ease-out;
}

.cross .second-line {
  animation: 0.7s draw-second-line ease-out;
}

@keyframes draw-first-line {
  0% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 0, 56;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 56, 330;
    stroke-dashoffset: 0;
  }
}

@keyframes draw-second-line {
  0% {
    stroke-dasharray: 0, 55;
    stroke-dashoffset: 1;
  }

  50% {
    stroke-dasharray: 0, 55;
    stroke-dashoffset: 1;
  }

  100% {
    stroke-dasharray: 55, 0;
    stroke-dashoffset: 70;
  }
}

.alert-sign {
  stroke-width: 6.25;
  stroke-linecap: round;
  position: absolute;
  top: 40px;
  left: 68px;
  width: 15px;
  height: 70px;
  animation: 0.5s alert-sign-bounce cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.alert-sign .dot {
  stroke: none;
  fill: #FFC107;
}

@keyframes alert-sign-bounce {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

/* End SVG Animate */

/* Switch toggle */
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 34px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
  margin-top: -20px;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 30px;
  height: 30px;
  border-radius: 45px;
  transition: 0.2s;
  background: var(--bt-defult);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

/* End Switch toggle */

/* Fade In */
.right-to-left-enter {
  transform: translateX(10%);
}

.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.right-to-left-exit {
  transform: translateX(0);
}

.right-to-left-exit-active {
  transform: translateX(0);
  transition: all 1s ease;
}

/* End Fide In */

.boxMarginTop {
  margin-top: 12px !important;
}

.titleRule {
  font-size: 18px;
  color: var(--bt-warning);
  /* border-bottom: solid 1px var(--bt-warning); */
  cursor: pointer;
}

.titleRule:hover {
  opacity: 0.8;
}

.boxAccountAgentQrcodeTitle {
  font-size: 20px;
  font-weight: 600;
}

.boxDetailAmountQrcodeNumber {
  margin-top: 10px;
}

.boxDetailAmountQrcodeNumberText {
  color: var(--bt-danger);
  font-size: 22px;
  font-weight: 600;
  margin: 0px 6px;
}

.boxDetailQRCode {
  padding: 20px 0px;
}

.boxDetailQRCodeScan {
  display: inline-block;
  width: 300px;
  border: solid 2px rgba(13, 60, 102, 255);
  border-radius: 15px;
}

.boxDetailQRCodeBG {
  border-radius: 10px 10px 0px 0px;
  background: rgba(13, 60, 102, 255) !important;
}

.boxDetailQRCodeBGSupport {
  border-radius: 0px 0px 10px 10px;
  background: rgba(13, 60, 102, 255) !important;
  color: var(--bt-defult);
  font-size: 13px;
  padding: 8px;
}

.boxDetailQRCodeButtonSave {
  padding: 30px 0px 15px 0px;
}

.iconPromtpaySize {
  width: 120px;
  height: auto;
}

.boxDetailQRCodeGenTop {
  padding: 40px 0px 0px 0px;
  background: var(--bt-defult);
}

.boxDetailQRCodeScanNote {
  color: var(--bt-danger);
  padding: 20px 0px;
  font-size: 14px;
}

.popupLineTitle {
  color: var(--bt-sucess);
  font-weight: 600;
  font-size: 20px;
}

.popupLineDetail {
  color: var(--bt-defult);
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
}

.popupLineQRCode {
  margin-top: 40px;
  position: relative;
}

.popupLineQRCodeBG {
  display: inline-block;
  background-color: var(--bt-defult);
  padding: 10px;
  border-radius: 12px;
}

.popupLineIconPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popupLineIcon {
  width: 40px;
  height: auto;
  background-color: var(--bt-defult);
  border-radius: 8px;
  padding: 2px 4px;
}

.popupLineIconBtn {
  width: 32px;
  height: auto;
}

/* Responsive */
@media only screen and (max-width: 1280px) {
  .flexSizeLeft {
    flex: 0 0 22%;
  }

  .flexSizeRight {
    flex: 0 0 78%;
  }

  .cardMarginOther {
    padding: 12px;
  }
}

@media only screen and (max-width: 960px) {
  .boxAccountAgentQrcodeTitle {
    font-size: 18px;
  }

  #DepositApp .inputAdornment input,
  #WithdrawApp .inputAdornment input {
    padding: 3px 0 2px !important;
  }

  #DepositApp .MuiInputBase-input,
  #WithdrawApp .MuiInputBase-input {
    padding: 6px 0 4px !important;
    font-size: 18px !important;
  }

  .btnLogin,
  .btnRegister {
    border: 1px solid var(--bt-defult);
    font-size: 14px;
    width: 110px;
    padding: 2px 0px;
  }

  #DepositApp ::-webkit-scrollbar,
  #HomeApp ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: unset;
  }

  .homePosition {
    padding-top: 64px;
  }

  .wrapperActive {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 760px) {
  .popupLineIconBtn {
    width: 25px;
  }

  .btnLogin,
  .btnRegister {
    border: 1px solid var(--bt-defult);
    font-size: 12px;
    width: 80px;
    padding: 1px 0px;
  }

  .homePosition {
    padding-top: 64px;
  }

  .homePositionActive {
    padding-top: 108px;
  }

  .wrapper {
    padding-top: 10px;
  }

  .wrapperActive {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .HomeApp .slick-dots li {
    margin: 0px 3px;
    width: 12px;
    height: 12px;
  }

  .HomeApp .slick-dots li button:before {
    font-size: 18px !important;
  }

  .lds-ring {
    width: 60px;
    height: 60px;
  }

  .lds-ring div {
    width: 54px;
    height: 54px;
  }
}

@media only screen and (max-width: 580px) {
  .boxDetailQRCodeScanNote {
    font-size: 12px;
  }

  .preLoadderCenter {
    width: 100%;
  }

  .progressBox {
    width: 93%;
  }

  .dropdown {
    top: 40px;
    transform: translateX(-84%);
  }

  .textLoadding {
    margin-top: 24px;
    font-size: 16px;
  }

  .lds-shape ul {
    width: 60px;
  }

  .lds-shape li {
    width: 18px;
    height: 18px;
  }

  .boxGame {
    padding: 10px 0px;
  }

  .cardMargin {
    margin-top: 14px;
  }

  .slick-next {
    right: 0px !important;
  }

  .slick-prev {
    left: -14px !important;
  }

  .bgPadding {
    border-radius: 0px;
  }

  .HomeApp .slick-dots {
    top: -38px !important;
    right: 6px;
  }

  .HomeApp .MuiTab-textColorInherit,
  .HomeApp .MuiTab-textColorInherit.Mui-selected {
    font-size: 13px;
    margin-bottom: 8px;
    border-radius: 6px;
    padding: 16px 0px;
    margin-left: 0px;
  }

  .HomeApp .MuiTabs-scrollButtonsDesktop {
    display: unset;
  }

  .HomeApp .MuiTabScrollButton-vertical {
    background: var(--bg-card);
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 24px;
    margin-left: 0px;
  }

  .HomeApp .MuiSvgIcon-fontSizeSmall {
    font-size: 24px;
  }

  .PromotionApp {
    margin-top: 10px;
  }

  #DepositApp .MuiInputBase-input,
  #WithdrawApp .MuiInputBase-input {
    padding: 6px 0 4px !important;
    font-size: 18px !important;
  }
}

.pwaPopupInstallApp {
  background: var(--bt-defult);
  padding: 20px 24px;
  filter: drop-shadow(0 0 0.1rem #1F1F1F);
  border-radius: 0px 0px 6px 6px;
  width: 400px;
  color: var(--bt-undefult);
}

.pwaPopupInstallApp .pwaIconSize {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  /* padding: 8px 8px; */
}

.pwaPopupInstallApp .pwaIconTitle {
  font-size: 16px;
}

.pwaPopupInstallApp .pwaTitle {
  padding-left: 8px;
}

.positionFixed {
  position: fixed;
}

.positionRelative {
  position: relative;
}

.topLeft {
  top: 0;
  left: 0;
}

.topRight {
  top: 0;
  right: 0;
}

.topCenter {
  top: 50%;
}

.bottomLeft {
  bottom: 0;
  left: 0;
}

.bottomRight {
  bottom: 0;
  right: 0;
}

.bottomCenter {
  bottom: 0;
  left: 0;
}

.leftCenter {
  left: 50%;
}

.rightCenter {
  right: 50%;
}

.traslateXCenter {
  transform: translateX(-50%);
}

.traslateYCenter {
  transform: translateY(-50%);
}

.traslateCenter {
  transform: translate(-50%, -50%);
}

.displayInlineBlock {
  display: inline-block;
}

.zIndexMax {
  z-index: 9999;
}

.pwaPopupInstallAppMobie .zIndexMax {
  z-index: -1;
}

.textBoldMedium {
  font-weight: 500;
}

@media only screen and (max-width: 360px) {

  .HomeApp .MuiTab-textColorInherit,
  .HomeApp .MuiTab-textColorInherit.Mui-selected {
    font-size: 12px;
    margin-bottom: 8px;
    border-radius: 6px;
    padding: 14px 0px;
    margin-left: -1px;
  }

  .HomeApp .MuiTabScrollButton-vertical {
    margin-left: -1px;
  }
}

@media only screen and (max-width: 600px) {
  .pwaPopupInstallApp {
    padding: 5px 20px;
    width: auto;
  }

  .pwaPopupInstallApp .pwaIconTitle {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .pwaPopupInstallAppMobie .positionFixed {
    position: initial;
  }

  .pwaPopupInstallAppMobie .topLeft {
    top: 0;
    left: 0;
  }

  .pwaPopupInstallAppMobie .leftCenter {
    left: 50%;
  }

  .pwaPopupInstallAppMobie .traslateXCenter {
    transform: translateX(0%);
  }

  .pwaPopupInstallApp {
    background: var(--bt-defult);
    filter: inherit;
    border-radius: 0px 0px 0px 0px;
  }

  /* .pwaPopupInstallAppMobie .zIndexMax {
    z-index: 9998;
  } */

}

@media only screen and (max-width: 600px) {
  .pwaPopupInstallAppDesktop .zIndexMax {
    z-index: -1;
  }
}